var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "sideModal",
      class: [
        _vm.feature === "address" ? "address-custom-modal" : "custom-modal",
        _vm.show ? "show-modal" : "",
      ],
    },
    [
      _c(
        "div",
        { ref: "modalHeader", class: ["custom-header", _vm.headerClass] },
        [
          _vm.showClose
            ? _c("b-icon", {
                staticClass: "close-icon",
                attrs: { icon: "x-circle-fill", variant: "info", scale: "1.3" },
                on: { click: _vm.close },
              })
            : _vm._e(),
          _vm._t("header"),
        ],
        2
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "height",
              rawName: "v-height",
              value: _vm.setDimensions,
              expression: "setDimensions",
            },
          ],
          class: ["custom-body", _vm.bodyClass],
          style: { height: _vm.height - _vm.headerHeight + "px" },
        },
        [_vm._t("body")],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }