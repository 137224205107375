<template>
  <div
    :class="[
      feature === 'address' ? 'address-custom-modal' : 'custom-modal',
      show ? 'show-modal' : '',
    ]"
    ref="sideModal"
  >
    <div :class="['custom-header', headerClass]" ref="modalHeader">
      <b-icon
        v-if="showClose"
        class="close-icon"
        @click="close"
        icon="x-circle-fill"
        variant="info"
        scale="1.3"
      ></b-icon>
      <slot name="header"></slot>
    </div>
    <div
      v-height="setDimensions"
      :class="['custom-body', bodyClass]"
      :style="{ height: height - headerHeight + 'px' }"
    >
      <slot name="body"></slot>
    </div>
  </div>
</template>

<script>
import { isAndroid, mobileModel, isChrome } from "mobile-device-detect";
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    headerClass: {
      type: String,
    },
    bodyClass: {
      type: String,
    },
    showClose: {
      type: Boolean,
      default: true,
    },
    feature: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      height: document.documentElement.clientHeight,
      headerHeight: 0,
    };
  },
  watch: {
    show(val) {
      this.$store.commit("global/SET_MODAL_STATE", val);
    },
  },
  created() {
    addEventListener("popstate", this.popStateHandler);
  },
  mounted() {
    const hHeight = this.$refs.modalHeader.clientHeight;
    if (mobileModel == "iPhone") {
      if (isChrome) {
        this.headerHeight = hHeight + 150;
        // alert(this.headerHeight);
      }
    }
    if (isAndroid) {
      if (hHeight > 0) {
        this.headerHeight = hHeight + 15;
      }
    }
    this.headerHeight = hHeight;
    // console.log(mobileModel);
  },
  destroyed() {
    removeEventListener("popstate", this.popStateHandler);
  },
  methods: {
    close(event) {
      this.$store.commit("global/SET_MODAL_STATE", false);
      this.$emit("exit", event);
    },
    setDimensions({ height }) {
      this.height = height;
    },
    popStateHandler(event) {
      if (this.$store.state.global.isModalOpen) {
        this.close(event);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$width: 50%;

.custom-modal {
  position: fixed;
  width: $width;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  transform: translateX(100%);
  z-index: 600;
  background-color: $white;
  transition: transform 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  transition-delay: 0.15s;

  &.show-modal {
    transform: translateX(0);
  }

  @include media-breakpoint-down(md) {
    width: 100%;
  }

  .custom-header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    flex-flow: column;

    &.terms-title {
      justify-content: flex-start;
      padding: 15px 2rem;
    }

    .close-icon {
      position: absolute;
      top: 1rem;
      right: 1.6rem;
      cursor: pointer;
      z-index: 99;

      @include media-breakpoint-down(sm) {
        font-size: 1.5rem;
      }

      @include media-breakpoint-up(xxl) {
        font-size: 1.5rem;
      }
    }
  }

  .custom-body {
    padding: 2rem;

    &.terms-body {
      overflow: auto;
    }

    &.why-body {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
}

// Similar to custom-modal class above
.address-custom-modal {
  position: fixed;
  width: 40%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  transform: translateX(100%);
  z-index: 600;
  background-color: $white;
  transition: transform 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  transition-delay: 0.15s;

  &.show-modal {
    transform: translateX(0);
  }

  @include media-breakpoint-down(md) {
    width: 100%;
  }

  .custom-header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    flex-flow: column;

    &.terms-title {
      justify-content: flex-start;
      padding: 15px 2rem;
    }

    .close-icon {
      position: absolute;
      top: 1rem;
      right: 1.6rem;
      cursor: pointer;
      z-index: 99;

      @include media-breakpoint-down(sm) {
        font-size: 1.5rem;
      }

      @include media-breakpoint-up(xxl) {
        font-size: 1.5rem;
      }
    }
  }

  .custom-body {
    padding: 2rem;

    &.terms-body {
      overflow: auto;
    }

    &.why-body {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
}
</style>
